import React from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import axios from 'axios';
import {apiUrl} from './../Config/ApiSettings';
import WeekPlayListInfo from './WeekPlayListInfo';

function WeekPlaylistElementContainer() {

  let { scheduleDay } = useParams();
  return (
  	<div>
<WeekPlaylistElement path={scheduleDay} /></div>
  	)
}


class WeekPlaylistElement extends React.Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    programs: null,
    isLoading: true,
    errors: null,
  };





  // Now we're going to make a request for data using axios
  getPosts() {


    let schedule_query_done = this.props.path;


    console.log("test zmiennej: " + schedule_query_done)
    if (!schedule_query_done) {
        let schedule_query = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        schedule_query_done = schedule_query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");
    console.log("test znakow: " + schedule_query_done);
    }

// const start = 1 * 60 + 5;
//  const end =  2 * 60 + 57;
//  const date = new Date();
//  const now = date.getHours() * 60 + date.getMinutes();

//  if(start <= now && now <= end)
//    console.log("in time"); else {
//        console.log("not in time");
//


      // console.log("NIE");

      let program_category_query = schedule_query_done;



    axios
      // This is where the data is hosted
      .get(`${apiUrl}/ramowka/${program_category_query}/?api=true`, {
})
      // Once we get a response and store data, let's change the loading state
      .then(response => {
        this.setState({
          programs: response.data,
          isLoading: false
        });

      })
      // If we catch any errors connecting, let's update accordingly
            .catch(error => {
                console.log(error);
                    // window.location.href = '/not-found';
                this.setState({ error, isLoading: false } )
            });
  }
  // Let's our app know we're ready to render the data
  async componentDidMount() {
    this.getPosts();


            let scrollPos = 0;
            const nav = document.querySelector('.schedule-section .dynamic-boxes.top-right');
            const nav2 = document.querySelector('.schedule-section .dynamic-boxes.bottom-left');

            function checkPosition() {
              let windowY = window.scrollY;
              if (windowY < scrollPos) {
                // Scrolling UP
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
                nav2.classList.add('is-visible');
                nav2.classList.remove('is-hidden');
              } else {
                // Scrolling DOWN
                nav.classList.add('is-visible');
                nav.classList.remove('is-hidden');
                nav2.classList.add('is-visible');
                nav2.classList.remove('is-hidden');
              }
              scrollPos = windowY;
            }



            function debounce(func, wait = 10, immediate = true) {
              let timeout;
              return function() {
                let context = this, args = arguments;
                let later = function() {
                  timeout = null;
                  if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
              };
            };

            window.addEventListener('scroll', debounce(checkPosition))




            var isScrolling;

            // Listen for scroll events
            window.addEventListener('scroll', function ( event ) {
              // var startScrolling = setTimeout(function() {
                // Run the callback
                // console.log( 'Scrolling has started.' );
              // }, 1000);

              // Clear our timeout throughout the scroll
              window.clearTimeout( isScrolling );

              // Set a timeout to run after scrolling ends
              isScrolling = setTimeout(function() {
                nav.classList.remove('is-visible');
                nav2.classList.remove('is-visible');
                // Run the callback
                // console.log( 'Scrolling has stopped.' );

              }, 66);

            }, false);














  }


componentDidUpdate(prevProps, prevState) {
  // only update chart if the data has changed
  if (prevProps.path !== this.props.path) {
    this.getPosts();

  }
}



// static getDerivedStateFromProps(nextProps, prevState) {

//     this.getPosts();

// }

  // Putting that data to use
  render() {
    const { isLoading, programs } = this.state;


        let currentPath = this.props.path;
        let todayInit = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        let currentDay = todayInit.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l");
        // if ((currentPath == currentDay) || (!currentPath)) {
        //   console.log("prawda to")} else {console.log("bullszit")};
        //  ((currentPath == currentDay) || (!currentPath)) ? console.log("prawda to") : console.log("bullszit");
        let schedule_query = new Date().toLocaleDateString('pl-PL', {
            weekday: 'long'
        })
        // console.log(this.props);
        // if (programs !== null) {console.log (programs[0].program_day_title)};
    return (
      <React.Fragment>
        <h3 className="subpage-title">{ (((currentPath === currentDay) || (!currentPath)) ? schedule_query : ((programs !== null) ? (programs[0].program_day_title) : (currentPath) ))  }</h3>
        <ul className={"schedule-list " + (((currentPath === currentDay) || (!currentPath)) ? 'current-day' : '')}>
          {!isLoading ? (
            programs.map(program => {
              // program_day_title, program_day_name
              const { id, program_title, program_url, program_time_start, program_time_end } = program;

    let startTime = program_time_start;
    let endTime = program_time_end;
    let currentDate = new Date();
    let areaDate = new Date(currentDate.toLocaleString('en-US', {timeZone: 'Europe/Warsaw'}));
    let startDate = new Date(areaDate.getTime());
    startDate.setHours(startTime.split(":")[0]);
    startDate.setMinutes(startTime.split(":")[1]);
    let endDate = new Date(areaDate.getTime());
    endDate.setHours(endTime.split(":")[0]);
    endDate.setMinutes(endTime.split(":")[1]);
    let valid = startDate <= areaDate && endDate >= areaDate;




              return (
                <li className={"schedule-item " + (valid ? 'active' : '') + (((currentPath === currentDay) || (!currentPath)) ? ' current-day' : '')} key={id}>
                  <Link to={program_url}><span className="category-container"></span>
                  <span className="hours-container"><span className="hour-start">{program_time_start}</span><span className="hour-spr"> - </span><span className="hour-end">{program_time_end}</span> </span>
                  <span className="schedule--title">{program_title}</span></Link>
                </li>

              );
            })
          ) : (
            <p>Wczytywanie...</p>
          )}
        </ul>
        <WeekPlayListInfo />
      </React.Fragment>
    );
  }
}

export default WeekPlaylistElementContainer;