import React from "react";
import { useAPI } from "./../Service/HomeDataContext";
import {
  Link
} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function HomeSlider() {
  const { homedata } = useAPI();
  // console.log(homedata);

  let slidesData = [];
  if (homedata[0] !== undefined ) {
      slidesData = homedata[0].slides;
      // console.log(slidesData);
    };


    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };


  return (


      <div className="home-slider-section news-slider">
        <Slider {...settings}>


            {slidesData.map(u => (



                    <div className={'news-single-slide layout-type'+u.layout+' bg-'+u.background} key={u.id}>
                      <div className="slide-photo">
                        {(u.external === 0) ? (
                          <Link to={u.url} aria-label={u.title}><img fetchpriority="high" src={u.image} alt="" width="840" height="538" /></Link>
                          ) : ( <a href={u.url} target="_blank" rel="noopener noreferrer"><img src={u.image} alt="" /></a>  )}
                      </div>
                      <div className="slide-content">
                        <h3 className="slide-title">
                        {(u.external === 0) ? (
                        <Link to={u.url}><span dangerouslySetInnerHTML={ { __html: u.title }} /></Link>
                        ) : ( <a href={u.url} target="_blank" rel="noopener noreferrer"><span dangerouslySetInnerHTML={ { __html: u.title }} /></a>  )}
                        </h3>
                        <span className="slide-description-text" dangerouslySetInnerHTML={ { __html: u.description }} />
                      </div>
                      {(u.logo) ? (<div className="slide-audition-logo">
                        <img src={u.logo} alt="" />
                      </div>) : ''}
                    </div>



            ))}

        </Slider>
      </div>

  );
}

