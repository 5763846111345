import React from "react";
import {
  Link,
} from "react-router-dom";
import LogoFooter from './../Assets/img/Logo_stopka.svg';
import SocialMenu from './SocialMenu';
import { useAPI } from "./../Service/HomeDataContext";

export default function Footer() {
  const { homedata } = useAPI();
  // console.log(homedata);


  let footerData = [];
  let column_2b = [];
  let menu_footer = [];
  if (homedata[0] !== undefined ) {
      footerData = homedata[0].footer[0];
      // console.log(homedata[0].footer[0]);
			column_2b = footerData.col_2b;
			menu_footer = footerData.menu_footer;
    };

  return (


			<footer className="site-footer">

				<div className="container-grid">
					<div className="column pos-1">
							<div dangerouslySetInnerHTML={ { __html: footerData.col_1 }} />
					</div>
					<div className="column pos-2a">
						<h4>{footerData.col_2a_title}</h4>
						<div className="contact-data">
							<div className="address">
							<div dangerouslySetInnerHTML={ { __html: footerData.col_2a_txt }} />
							</div>
						</div>
					</div>
					<div className="column pos-2b">
						<div className="contact-data">

								{
			            (column_2b) ? ( column_2b.map(column2b => {

			              const { id, title, txt } = column2b;
			              return (
									 			<div key={id} className="email-contact">
														<h5>{title}</h5>
														<div dangerouslySetInnerHTML={ { __html: txt }} />
													</div>
			              );
			            }) ) : ( <span /> )
								}



						</div>
					</div>
					<div className="column pos-3">
							<div dangerouslySetInnerHTML={ { __html: footerData.col_3 }} />
					</div>
					<div className="column pos-4">
						<SocialMenu />
					</div>

					<div className="column pos-5">
						<div className="footer--logo"><img src={LogoFooter} alt="Radio Kampus 97,1 FM" width="92" height="59" /></div>
						<div className="footer--copyright"><div dangerouslySetInnerHTML={ { __html: footerData.col_5 }} /></div>
					</div>

					<div className="column pos-6">
						<ul className="bottom-menu">
								{
			            (menu_footer) ? ( menu_footer.map(menu => {

			              const { id, title, url } = menu;
			              return (
									 			<li key={id}><Link to={url}>{title}</Link></li>
			              );
			            }) ) : ( <span /> )
								}
						</ul>
						</div>
					</div>
			</footer>


  );
}