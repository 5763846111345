import React from 'react';
import { bool, func } from 'prop-types';
const MainMenuTrigger = ({ open, setOpen, ...props }) => {

function blockBody() {
	const bodyLocker = document.querySelector('body,html');
	bodyLocker.classList.toggle('locked');
    console.log('Blokada body');
  }


  const isExpanded = open ? true : false;
  return (
<button type="button" className="menu-center" aria-labelledby="Toggle menu" aria-expanded={isExpanded} open={open} onClick={() => { setOpen(!open); blockBody();}} {...props}>{(!open) ? 'menu' : 'zamknij'}</button>
  )
}
MainMenuTrigger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};
export default MainMenuTrigger;